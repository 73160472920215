//
//
//
//
//
//
export default {
  mounted: function mounted() {
    var tabbar = document.querySelector('.van-tabbar');

    if (tabbar) {
      tabbar.style.display = 'none';
    }
  },
  beforeDestroy: function beforeDestroy() {
    var tabbar = document.querySelector('.van-tabbar');

    if (tabbar) {
      tabbar.style.display = '';
    }
  }
};